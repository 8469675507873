import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import Text from "../../../Components/Text/Text";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../Styles/LeasderShipWraper.css";

import LeaderShipLink from "../Components/LeaderShipLink/LeaderShipLink";
export default function LeasderShipWraper() {
  return (
    <div className="LeasderShipWraper">
      <Helmet>
        <title>Our Leadership </title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />
          <div className="leadershipdetails">
            <Text text="Our Leadership" FontSize="3rem" FontWeight="700" />
          </div>
          <div className="leadershipcontainer">
            <LeaderShipLink
              RouteLink="/founder&ceo"
              Src="/Images/leadershipImage.png"
              LeaderName="Ali Akhtar"
              LeaderDesignation="Founder & CEO"
            />
            <LeaderShipLink
              RouteLink="/board_chairman"
              Src="/Images/hamidNawazchairmanpage.webp"
              LeaderName="Hamid Nawaz"
              LeaderDesignation="Chairman of the Board"
            />
            <LeaderShipLink
              RouteLink="/head_of_bu"
              Src="/Images/Aamirleadershipwraperimage3.png"
              LeaderName="M. Aamir Bashir"
              LeaderDesignation="Head of BU - Defense"
            />
          </div>
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>
    </div>
  );
}
