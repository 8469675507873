import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import Text from "../../../Components/Text/Text";
import Footer from "../../../Components/Footer/Footer";
import { Helmet } from "react-helmet";

export default function AamirHeadBu() {
  return (
    <div className="LeaderShip">
      <Helmet>
        <title>Head of BU - Defense</title>
      </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />

          <div className="leadershipdetails">
            <Text text="Muhammad Aamir Bashir " FontSize="4rem" FontWeight="700" />
            <Text
              text="Head of BU - Defense"
              FontSize="1.8rem"
              FontWeight="700"
            />
          </div>
          <div className="leadershipdetails2">
            <div className="leadershipImageMob">
              <ImageComp
                Src="/Images/Aamirleadershipwraperimage.png"
                Radius="120px"
              />
            </div>
            <div className="leaderhsipconent">
              <Text
                text="Aamir is a seasoned technology executive, renowned for his expertise in the telecommunications and ICT industry. He graduated as an Avionics Engineer from NUST and began his career as a field engineer in 1998. Aamir also has a Masters in IT from NUST, majoring in networks."
                Color="black"
              />
              <Text
                text="Aamir has held leadership positions in sourcing, operations, project   management, and product management. He has successfully led the execution of numerous large-scale technology projects. Aamir’s expertise includes LTE/4G, satellite communications and communication networks."
                Margin="35px 0px"
                Color="black"
              />
              <Text
                text="Aamir has earned multiple awards and commendations throughout his career for his exemplary work."
                Color="black"
                Margin="35px 0px"
              />
            </div>
            <div className="leadershipImage">
              <ImageComp
                Src="/Images/Aamirleadershipwraperimage.png"
                Radius="120px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
        
    </div>
  );
}
