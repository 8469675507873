import React from "react";
import HeaderSection from "../../../Components/HeaderSection/HeaderSection";
import Text from "../../../Components/Text/Text";
import ImageComp from "../../../Components/ImageComp/ImageComp";
import "../Styles/LeaderShip.css";
import Footer from "../../../Components/Footer/Footer";
import Navbar from "../../../Components/Navbar/Navbar";

import { Helmet } from 'react-helmet';


export default function AliCEO() {
  return (
    <div className="LeaderShip">
      <Helmet>
        <title>Founder & CEO</title>
        </Helmet>
      <div className="Page_Body">
        <div className="Page_Body_wraper">
          <HeaderSection
            BannerText="Our Leadership "
            Src="/Images/leadershopbanner.webp"
            text="About Us <span>/</span>"
            text1="Our Leadership "
            RouteLink="/#aboutus"
            RouteLink1="/leadership"
            Color2="black"
            Color1="#6C6C6C"
          />

          <div className="leadershipdetails">
            <Text text="Ali Akhtar" FontSize="4rem" FontWeight="700" />
            <Text text="Founder & CEO" FontSize="1.8rem" FontWeight="700" />
          </div>
          <div className="leadershipdetails2">
            <div className="leadershipImageMob">
              <ImageComp Src="/Images/leadershipImage.png" />
            </div>
            <div className="leaderhsipconent">
              <Text
                text="Ali has been part of the space industry since 2010, beginning his career as a systems engineer designing satellite communications networks. By 2021, Ali had founded DynaSys Networks, establishing himself as a maverick in IoT. "
                Color="black"
              />
              <Text
                text="Ali works closely with the technology industry’s leaders ensuring that DynaSys Networks stays abreast of and contribute to the global technological roadmap of IoT and satellite communications. He has been championing the role of IoT and satellite networks with positive real-world impacts on the lives of millions of people, challenging the conventional wisdom and driving commercial growth.  "
                Margin="35px 0px"
                Color="black"
              />
              <Text
                text="Ali’s accomplishments have led to both him and his organizations receiving international recognition including community effort, innovation, startup and technology awards in satellite communications and IoT. Ali is the Asia Pacific Satellite Communication Council’s first Young Talent Award recipient. He features in the first cohort of the Space and Satellite Professionals International’s “20 under 35” making a positive impact on the industry. He is also a fellow at the USA based Institute of Space Commerce."
                Color="black"
              />
            </div>
            <div className="leadershipImage">
              <ImageComp Src="/Images/leadershipImage.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="borderSec">
        <div className="border"></div>
      </div>
        
    </div>
  );
}
